<template>
  <div v-loading="loading" class="examTeacher">
    <div class="card"><detailsHeader :show-popver="false" /></div>
    <div class="operate card">
      <div class="filter">
        <div v-if="$route.name != 'unionExamNewTeacher'" class="filter-item">
          学校：
          <el-select
            v-model="search.schoolIdSub"
            clearable
            filterable
            placeholder="请选择"
            @change="filterData"
          >
            <el-option
              v-for="item in schoolList"
              :key="item.schoolId"
              :label="item.schoolName"
              :value="item.schoolId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          科目：
          <el-select
            v-model="search.subjectId"
            clearable
            filterable
            placeholder="请选择"
            @change="filterData"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.subjectId"
              :label="item.subjectName"
              :value="item.subjectId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          分类：
          <el-select
            v-model="search.type"
            clearable
            filterable
            placeholder="请选择"
            @change="filterData"
          >
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          搜索：
          <el-input
            v-model="search.keyword"
            placeholder="输入姓名、用户名或编码"
            clearable
            style="width: 204px"
            @input="filterData"
          ></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button
          v-if="$route.name != 'unionExamNewTeacher'"
          plain
          type="primary"
          @click="add"
          >新增</el-button
        >
        <el-button
          v-if="$route.name != 'unionExamNewTeacher'"
          plain
          type="primary"
          @click="importUniUser"
          >导入联考用户</el-button
        >
        <el-button plain type="primary" @click="importSchoolUser"
          >导入校内用户</el-button
        >
        <el-button
          v-if="$route.name != 'unionExamNewTeacher'"
          plain
          type="primary"
          @click="importTeacher"
          >导入</el-button
        >
        <!-- <el-button plain type="success" @click="exportTeacher">导出</el-button> -->
        <el-button plain type="success" @click="exportTeacherDialog"
          >导出</el-button
        >
        <el-button plain type="danger" @click="clearBySchool">清空</el-button>
      </div>
    </div>
    <div class="card">
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        tooltip-effect="dark"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column prop="teacherName" label="姓名" align="center" />
        <el-table-column prop="schoolName" label="学校" align="center" />
        <el-table-column prop="username" label="用户名" align="center" />
        <el-table-column prop="password" label="密码" align="center" />
        <el-table-column prop="email" label="邮箱" align="center" />
        <el-table-column prop="subjectName" label="科目" align="center" />
        <el-table-column prop="type" label="分类" align="center" />
        <el-table-column prop="encoding" label="编码" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button v-if="row.edit == 1" type="text" @click="edit(row)"
              >修改</el-button
            >
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="prev, pager, next,sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <footerTab
      v-if="showTab"
      :multiple-selection="multipleSelection"
      @hidden="hidden"
      @delete="deleteStudent"
    />
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      custom-class="dialog"
      :width="dialog"
      :before-close="handleClose"
    >
      <div v-if="title === '新增阅卷教师' || title === '编辑阅卷老师'">
        <el-form
          ref="form"
          label-position="right"
          label-width="90px"
          :rules="rules"
          :model="form"
        >
          <el-form-item label="学校：" prop="schoolIdSub">
            <el-select
              v-model="form.schoolIdSub"
              :disabled="title === '编辑阅卷老师'"
              clearable
              filterable
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名：" prop="teacherName">
                <el-input
                  v-model="form.teacherName"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户名：" prop="username">
                <el-input
                  v-model="form.username"
                  placeholder="请输入用户名"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="密码：" prop="password">
                <el-input
                  v-model="form.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱：" prop="email">
                <el-input
                  v-model="form.email"
                  placeholder="请输入邮箱"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="分类：" prop="type">
                <el-input
                  v-model="form.type"
                  placeholder="请输入分类"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="编码：" prop="encoding">
                <el-input
                  v-model="form.encoding"
                  placeholder="请输入编码"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="科目：">
            <el-button type="text" @click="handleCheckAllChange"
              >全选</el-button
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group v-model="subjectIds">
              <el-checkbox
                v-for="item in subjectOptions"
                :key="item.subjectId"
                :label="item.subjectId"
                >{{ item.subjectName }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="title === '导入阅卷教师'">
        <!-- <div class="row">
          初始密码：<el-input
            v-model="initPasswrod"
            style="width: 180px"
          ></el-input>
        </div> -->
        <div class="row">
          当前仅支持excel文件，<span class="btn" @click="downloadModel"
            >下载Excel模板</span
          >
        </div>
        <el-upload
          class="upload-demo"
          file="file"
          :show-file-list="false"
          :headers="uploadHeaders"
          :action="examTeacherImportURL"
          :before-upload="handleImportBefore"
          :on-success="handleOnsuccess"
          accept=".xlsx,.xls"
          drag
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div v-if="title === `从“用户管理”导入用户账号进行阅卷`">
        <div class="ps">
          <i class="el-icon-info"></i>
          注：该功能可以导入“用户管理”功能中的用户账号批阅本次联考，导入后只能只用不能修改账号信息！
        </div>
        <div class="title">方式1：批量设置</div>
        <div class="filter mb24">
          <div class="filter-item">
            选择分类：
            <el-select
              v-model="batch.type"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="$route.name != 'unionExamNewTeacher'" class="filter-item">
            选择分类：
            <el-select
              v-model="batch.schoolIdSub"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            科目：
            <el-select
              v-model="batch.subjectId"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.subjectId"
                :label="item.subjectName"
                :value="item.subjectId"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            type="primary"
            :loading="submitLoading"
            @click="batchImport"
            >批量导入</el-button
          >
        </div>
        <div class="title">方式2：选择</div>
        <div class="filter">
          <div class="filter-item">
            选择用户：
            <el-select
              v-model="choose.type"
              clearable
              filterable
              placeholder="请选择"
              @change="getImportTeacher"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            选择学校：
            <el-select
              v-model="choose.schoolIdSub"
              clearable
              filterable
              placeholder="请选择"
              @change="getImportTeacher"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            科目：
            <el-select
              v-model="choose.subjectId"
              clearable
              filterable
              placeholder="请选择"
              @change="getImportTeacher"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.subjectId"
                :label="item.subjectName"
                :value="item.subjectId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item" style="margin-top: 24px">
            搜索：
            <el-input
              v-model="choose.keyword"
              style="width: 280px"
              placeholder="输入姓名或用户名搜索"
              @input="getImportTeacher"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
        <div class="choose">
          <div class="choose-Box">
            <div class="header">
              <div>选择教师</div>
              <el-button type="text" @click="checkList = chooseList"
                >全选</el-button
              >
            </div>
            <div class="content">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in chooseList"
                  :key="index"
                  :label="item"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="choose-Box">
            <div class="header">
              <div>已选用户</div>
              <el-button type="text" class="clear" @click="checkList = []"
                >清空</el-button
              >
            </div>
            <div class="content">
              <div
                v-for="(item, index) in checkList"
                :key="index"
                class="content-item"
              >
                <div>{{ item.name }}</div>
                <div class="del" @click="delItem(index)">
                  <i class="el-icon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="title === '从“校内教师信息管理”导入教师账号进行阅卷'">
        <div class="ps">
          <i class="el-icon-info"></i>
          注：该功能可以导入“教师信息管理”功能中的用户账号批阅本次联考！
        </div>
        <div class="filter">
          <div
            v-if="$route.name != 'unionExamNewTeacher'"
            class="filter-item"
            style="margin-bottom: 24px"
          >
            选择学校：
            <el-select
              v-model="choose.type"
              clearable
              filterable
              placeholder="请选择"
              @change="getImportSchoolTeacher"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div
            v-if="$route.name != 'unionExamNewTeacher'"
            class="filter-item"
            style="margin-bottom: 24px"
          >
            选择学校：
            <el-select
              v-model="choose.schoolIdSub"
              clearable
              filterable
              placeholder="请选择"
              @change="getImportSchoolTeacher"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.schoolId"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item" style="margin-bottom: 24px">
            科目：
            <el-select
              v-model="choose.subjectId"
              clearable
              filterable
              placeholder="请选择"
              @change="getImportSchoolTeacher"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.subjectId"
                :label="item.subjectName"
                :value="item.subjectId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item" style="margin-bottom: 24px">
            搜索：
            <el-input
              v-model="choose.keyword"
              style="width: 280px"
              placeholder="输入姓名或用户名搜索"
              @input="getImportSchoolTeacher"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
        <div class="choose">
          <div class="choose-Box">
            <div class="header">
              <div>选择教师</div>
              <el-button type="text" @click="checkList = chooseList"
                >全选</el-button
              >
            </div>
            <div v-loading="chooseloading" class="content">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in chooseList"
                  :key="index"
                  :label="item"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="choose-Box">
            <div class="header">
              <div>已选用户</div>
              <el-button type="text" class="clear" @click="checkList = []"
                >清空</el-button
              >
            </div>
            <div class="content">
              <div
                v-for="(item, index) in checkList"
                :key="index"
                class="content-item"
              >
                <div>{{ item.name }}</div>
                <div class="del" @click="delItem(index)">
                  <i class="el-icon-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="title === '清空阅卷教师'">
        选择学校：
        <el-select
          v-model="schoolId"
          clearable
          filterable
          placeholder="请选择"
          @change="getImportTeacher"
        >
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="title === `导出阅卷老师信息`">
        选择学校：
        <el-select
          v-model="exportSchool"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-if="title == '导出阅卷老师信息' && roleId == 6"
            label="全部"
            value="0"
          ></el-option>
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          v-if="title === `清空阅卷教师`"
          type="primary"
          :loading="submitLoading"
          @click="clearTeacher"
        >
          确 定</el-button
        >
        <el-button
          v-if="title === `导出阅卷老师信息`"
          type="primary"
          :loading="submitLoading"
          @click="exportTeacher"
        >
          确 定</el-button
        >

        <el-button
          v-if="title === `从“校内教师信息管理”导入教师账号进行阅卷`"
          type="primary"
          :loading="submitLoading"
          @click="SchoolImportTeacher"
        >
          确 定</el-button
        >
        <el-button
          v-if="title === `从“用户管理”导入用户账号进行阅卷`"
          :loading="submitLoading"
          type="primary"
          @click="conditionImportTeacher"
        >
          确 定
        </el-button>
        <el-button
          v-if="title === '新增阅卷教师' || title === '编辑阅卷老师'"
          :loading="submitLoading"
          type="primary"
          @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  examteacher,
  addExamteacher,
  delExamteacher,
  editExamteacher,
  schoolteachertype,
  examinationuserBatch,
  examinationuserQuery,
  schoolteacher,
  deletebyschoolid,
  deleteBatch,
  getSchoolteacher,
  exportExamTeacher,
} from "@/core/api/exam/examTeacher";
import { schoollistbyexamid, getObj } from "@/core/api/exam/exam";
import detailsHeader from "@/components/detailsHeader.vue";
import { getStore } from "@/core/util/store";
import footerTab from "./component/footerTab.vue";
import { fileDownloadByUrl } from "@/core/util/util";
import { getSubjectList } from "@/core/util/util";
export default {
  name: "ExamTeacher",
  components: {
    detailsHeader,
    footerTab,
  },
  data() {
    const { roleId, schoolId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    return {
      initPasswrod: "",
      search: {
        current: 1,
        size: 50,
        schoolId: schoolId,
      },
      tableData: [],
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      dialogVisible: false,
      title: "",
      total: 0,
      schoolList: [],
      subjectList: [],
      loading: false,
      showTab: false,
      multipleSelection: [],
      dialog: "30%",
      form: {
        schoolId: schoolId,
      },
      rules: {
        schoolIdSub: [
          { required: true, message: "请选择学校", trigger: "blur" },
        ],
        teacherName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      subjectOptions: [],
      isIndeterminate: false,
      checkAll: false,
      subjectIds: [],
      submitLoading: false,
      typeOptions: [],
      batch: {},
      choose: {},
      checkList: [],
      chooseList: [],
      schoolId: "",
      exportSchool: "",
      chooseloading: false,
      roleId: roleId,
      examTeacherImportURL:
        "/exam/examteacher/import?examId=" + this.$route.query.examId,
    };
  },
  mounted() {
    this.getSchoollistbyexamid(); // 获取参与考试的学校
    this.getExamDetail();
    this.getTeacherList();
    this.getSchoolteachertype();
    this.subjectOptions = getSubjectList();
  },
  methods: {
    exportTeacherDialog() {
      this.dialogVisible = true;
      this.title = "导出阅卷老师信息";
      this.dialog = "30%";
    },
    exportTeacher() {
      this.submitLoading = true;
      exportExamTeacher({
        ...this.search,
        schoolId: this.exportSchool,
        examId: this.$route.query.examId,
      }).then((res) => {
        fileDownloadByUrl(res.data.data.url, res.data.data.name);
        this.submitLoading = false;
        this.dialogVisible = false;
        this.schoolId = "";
      });
    },
    handleImportBefore(file) {
      if (!(file.name.endsWith(".xlsx") || file.name.endsWith(".xls"))) {
        this.$message.error("只能上传 Excel 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      return true;
    },
    handleOnsuccess(res) {
      if (res.data.indexOf("https") != -1) {
        window.open(res.data);
      }
      this.dialogVisible = false;
    },
    delItem(index) {
      this.checkList.splice(index, 1);
    },
    async clearTeacher() {
      this.submitLoading = true;
      await deletebyschoolid({
        examId: this.$route.query.examId,
        schoolId: this.schoolId,
      });
      this.getTeacherList();
      this.dialogVisible = false;
      this.submitLoading = false;
    },
    clearBySchool() {
      this.title = "清空阅卷教师";
      this.dialogVisible = true;
      this.dialog = "30%";
    },
    async getImportTeacher() {
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      const res = await examinationuserBatch({
        ...this.choose,
        examId: this.$route.query.examId,
        flag: 1,
        schoolId: schoolId,
      });
      this.chooseList = res.data.data;
    },
    async conditionImportTeacher() {
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.submitLoading = true;
      let schoolTeacherVOS = this.checkList.map((item) => {
        return {
          id: item.id,
          schoolIdSub: item.schoolIdSub,
          schoolId: schoolId,
          name: item.name,
          username: item.username,
          password: item.password,
          subjectId: item.subjectId,
        };
      });
      try {
        await examinationuserQuery({
          schoolTeacherVOS: schoolTeacherVOS,
          examId: this.$route.query.examId,
          flag: 0,
        });
        this.search.current = 1;
        this.dialogVisible = false;
        this.getTeacherList();
        this.getSchoolteachertype();
        this.submitLoading = false;

        this.checkList = [];
      } catch {
        this.submitLoading = false;
      }
    },
    async batchImport() {
      this.submitLoading = true;
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      try {
        await examinationuserBatch({
          ...this.batch,
          examId: this.$route.query.examId,
          schoolId: schoolId,
          flag: 0,
        });
        this.submitLoading = false;
        this.search.current = 1;
        this.dialogVisible = false;
        this.getTeacherList();
        this.getSchoolteachertype();
        this.batch = {};
      } catch {
        this.submitLoading = false;
      }
    },
    importUniUser() {
      this.title = `从“用户管理”导入用户账号进行阅卷`;
      this.dialogVisible = true;
      this.getImportTeacher();
      this.dialog = "50%";
    },
    async SchoolImportTeacher() {
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      let schoolTeacherVOS = this.checkList.map((item) => {
        return {
          id: item.id,
          schoolIdSub: item.schoolId,
          schoolId: schoolId,
          name: item.name,
          username: item.username,
          password: item.password,
          subjectId: item.subjectId,
        };
      });
      try {
        await schoolteacher({
          schoolTeacherVOS: schoolTeacherVOS,
          examId: this.$route.query.examId,
          flag: 1,
        });
        this.submitLoading = false;
        this.search.current = 1;
        this.dialogVisible = false;
        this.chooseList = [];
        this.checkList = [];
        this.getTeacherList();
        this.getSchoolteachertype();
      } catch {
        this.submitLoading = false;
      }
    },
    importSchoolUser() {
      this.title = `从“校内教师信息管理”导入教师账号进行阅卷`;
      this.dialogVisible = true;
      if (this.schoolList.length) {
        this.choose.schoolIdSub = this.schoolList[0].schoolId;
      }
      this.getImportSchoolTeacher();
      this.dialog = "50%";
    },
    async getImportSchoolTeacher() {
      this.chooseloading = true;
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      const res = await getSchoolteacher({
        ...this.choose,
        schoolId: schoolId,
        examId: this.$route.query.examId,
      });
      this.chooseList = res.data.data;
      this.chooseloading = false;
    },

    async getSchoolteachertype() {
      const res = await schoolteachertype({ examId: this.$route.query.examId });
      this.typeOptions = res.data.data;
    },
    importTeacher() {
      this.title = "导入阅卷教师";
      this.dialogVisible = true;
      this.initPasswrod = "";
      this.dialog = "30%";
    },

    handleCheckAllChange() {
      this.subjectIds = this.subjectOptions.map((item) => item.subjectId);
    },
    handleClose() {
      this.dialogVisible = false;
      this.batch = {};
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.form = {
        schoolId: schoolId,
      };
      this.chooseList = [];
    },
    hidden() {
      this.multipleSelection = [];
      this.showTab = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.showTab = true;
      }
    },
    deleteStudent() {
      let data = this.multipleSelection.map((item) => item.id);
      deleteBatch([...data])
        .then(() => {
          this.getTeacherList();
          this.$message.success("删除成功");
          this.showTab = false;
        })
        .catch(() => {
          this.getTeacherList();
        });
    },
    edit(row) {
      this.dialogVisible = true;
      this.title = "编辑阅卷老师";
      this.form = { ...row };
      this.$set(this.form, "username", row.userName);
      this.subjectIds = this.form.subjectId
        .split(",")
        .map((item) => Number(item));
    },
    del(row) {
      this.$confirm("此操作将永久删除该老师, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delExamteacher({ id: row.id }).then(() => {
            this.$message.success("删除成功");
            this.getTeacherList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getTeacherList();
    },
    handleCurrentChange(val) {
      this.search.size = val;
      this.getTeacherList();
    },
    filterData() {
      this.search.current = 1;
      this.getTeacherList();
    },
    async getTeacherList() {
      this.loading = true;
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      try {
        const res = await examteacher({
          ...this.search,
          examId: this.$route.query.examId,
          schoolId: schoolId,
        });
        this.loading = false;
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      } catch {
        this.loading = false;
      }
    },
    async getSchoollistbyexamid() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      if (this.roleId != 6) {
        const { schoolId } = this.search;
        this.schoolList = res.data.data.filter(
          (item) => item.schoolId == schoolId
        );
      } else {
        this.schoolList = res.data.data;
      }
    },
    async getExamDetail() {
      // 获取 考试详情
      const res = await getObj(this.$route.query.examId);
      this.subjectList = res.data.data.paperList;
    },
    add() {
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.form = {
        schoolId: schoolId,
      };
      this.dialogVisible = true;
      this.title = "新增阅卷教师";
      this.form.examId = this.$route.query.examId;
      this.dialog = "50%";
    },
    downloadModel() {
      window.open(
        "https://jzjx-resource.oss-cn-chengdu.aliyuncs.com/analy/template/%E9%98%85%E5%8D%B7%E6%95%99%E5%B8%88%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xls"
      );
    },
    submit() {
      this.submitLoading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$set(this.form, "subjectId", this.subjectIds.toString());
          if (this.title === "新增阅卷教师") {
            addExamteacher({ ...this.form })
              .then(() => {
                this.dialogVisible = false;
                this.$message.success("新增成功");
                this.getTeacherList();
                this.submitLoading = false;
                this.subjectIds = [];
                this.getSchoolteachertype();
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else if (this.title === "编辑阅卷老师") {
            editExamteacher({ ...this.form })
              .then(() => {
                this.dialogVisible = false;
                this.$message.success("编辑成功");
                this.getTeacherList();
                this.submitLoading = false;
                this.subjectIds = [];
                this.getSchoolteachertype();
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.examTeacher {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 24px 0;
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .btn {
      color: #2474ed;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.ps {
  background: #fff3f3;
  border: 1px dashed #d92020;
  box-sizing: border-box;
  padding: 10px 13px;
  font-size: 14px;
  color: #605757;
  margin-bottom: 24px;
  i {
    color: #d92020;
  }
}
.title {
  color: #2474ed;
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: bold;
}
.filter {
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-wrap: wrap;
  &-item {
    margin-right: 18px;
    .el-select {
      width: 140px;
    }
  }
}
.mb24 {
  margin-bottom: 24px;
}
.choose {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  .choose-Box {
    flex: 1;
    margin-right: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      background: #f5f7fa;
      box-sizing: border-box;
      padding: 0 24px;
      border: 1px solid #dee1e3;
      font-size: 14px;
      .clear {
        color: #d92020;
      }
    }
    .content {
      height: 279px;
      border: 1px solid #dee1e3;
      overflow-y: auto;
      border-top: none;
      box-sizing: border-box;
      padding: 24px;
      .el-checkbox {
        display: block;
        margin-bottom: 24px;
      }
      .content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #2474ed;
        font-size: 14px;
        margin-bottom: 24px;
        i {
          color: #101011;
          cursor: pointer;
          &:hover {
            color: #2474ed;
          }
        }
      }
    }
  }
}
</style>
